import React from "react";
import SEO from "../components/seo/seo";
import { injectIntl } from "gatsby-plugin-react-intl";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import MachineSelector from "../components/machineSelector/MachineSelector";
import SideVerticalText from "../components/layout/sideVerticalText";
import BottomFactory from "../components/layout/BottomFactory";
import { TiArrowSortedDown } from "react-icons/ti";
import scrollTo from "gatsby-plugin-smoothscroll";
import FadeIn from "../components/animations/FadeIn";


const Machines = ({ intl }) => {
	const images = useStaticQuery(graphql`{
  machineBrain: file(
    relativePath: {eq: "machines/machineSelector/machineBrain.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 700, quality: 100, layout: CONSTRAINED, placeholder: NONE)
    }
  }
  sectionPic: file(relativePath: {eq: "homePage/sectionPic.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 100, layout: FIXED, placeholder: NONE)
    }
  }
}
`);
	return <>
        <SEO title={intl.formatMessage({ id: "navbar.machine" })} />
        <div className="machineSelectorPage">


            <div className="gridMachinePageSectionHome">
                
                <div className="boxMachinePageSideText">
                    <FadeIn duration="2s" delay="0.5s"><SideVerticalText /></FadeIn>
                </div>

                <div className="boxMachinePageLeft">
                    <p className="machineTitle">
                    {intl.formatMessage({ id: "machineSelection.title" })}
                    </p>
                    <p className="machineText">
                        {intl.formatMessage({ id: "machineSelection.text01" })}
                    </p>
                    <p className="machineText">
                        {intl.formatMessage({ id: "machineSelection.text02" })}
                    </p>
                    <div className="machinePageLearnSection">
                        <svg width="1" viewBox="0 0 2 135" fill="none" alt="tehnomatikSvg">
                            <line
                                x1="0.499986"
                                y1="0.996269"
                                x2="1.49999"
                                y2="134.996"
                                stroke="#3A3C40"
                            />
                        </svg>
                        <button
                            className="learnMoreButton"
                            onClick={() => scrollTo("#machineSelector")}
                        >
                            <TiArrowSortedDown size={28} />
                        </button>
                    </div>
                </div>

                <div className="boxMachinePageRight machinesHomeImageBox">
                
                        <GatsbyImage
                            image={images.machineBrain.childImageSharp.gatsbyImageData}
                            className="machineBrainImage"
                            alt="machineBrainImage"
                            loading="eager" />
            
                </div>

                <div className="boxMachinePageEmptyRight"></div>

                <div className="boxMachinePageBottomText">
                    <BottomFactory />
                </div>
            </div>





            <div className="machineSelectorSection" id="machineSelector">
                <div className="machineSelectorSvgTop" >
                    <svg viewBox="0 0 1920 61" alt="tehnomatikSvg">
                        <path
                            d="M1920 61H0C0 61 544 9.27349e-06 974.5 0C1405 -9.27349e-06 1920 61 1920 61Z"
                            fill="#3A3C40"
                        />
                    </svg>
                </div>
                <div className="machineSelectorTextWrapper">
                    <p className="machineSelectorTitle">
                        {intl.formatMessage({
                            id: "machineSelection.cardSelection",
                        })}
                    </p>

                    <p className="machineSelectorText">
                        {intl.formatMessage({
                            id: "machineSelection.text03",
                        })}
                    </p>
                </div>

                <div className="machineSelectorContainer">
                    <MachineSelector />
                </div>
            </div>
        </div>
    </>;
};

export default injectIntl(Machines);
