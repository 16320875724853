import React from "react";
import { FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const MachineSelector = () => {
	const data = useStaticQuery(graphql`{
  allMachineSelectorDataJson {
    edges {
      node {
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 90, quality: 100, layout: CONSTRAINED, placeholder: NONE)
          }
        }
        path
        text
        colorClass
      }
    }
  }
  processIcon: file(
    relativePath: {eq: "machines/machineSelector/processIcon.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 228, quality: 100, layout: CONSTRAINED, placeholder: NONE)
    }
  }
}
`);

	return (
        <div className="machineSelectorComponent">
			<div className="machineSelectorGridDivLg">
				<svg
					className="machineSelectorGridLg"
					viewBox="0 0 1098 52"
					fill="none"
					alt="tehnomatikSvg"
				>
					<line x1="1097" y1="52" x2="1097" y2="2" stroke="#949292" />
					<line x1="731.74" y1="52" x2="731.74" y2="2" stroke="#949292" />
					<line x1="366.48" y1="52" x2="366.48" y2="2" stroke="#949292" />
					<line x1="1.2207" y1="52" x2="1.2207" y2="2" stroke="#949292" />
					<line y1="1" x2="1098" y2="1" stroke="#949292" />
				</svg>
			</div>
			<div className="machineSelectorGridDivMid">
				<svg
					className="machineSelectorGridMid"
					viewBox="0 0 260 29"
					fill="none"
					alt="tehnomatikSvg"
				>
					<path d="M1 29V1H259V29" stroke="#949292" />
				</svg>
			</div>
			<div className="machineSelectorFirstLevel">
				{data.allMachineSelectorDataJson.edges.map((item, index) => {
					return (
                        <Link
							key={index}
							to={item.node.path}
							className="machineSelectorLinks"
						>
							<div className="machineSelectorCardWrapper">
								<div className="machineSelectorIcon">
									<GatsbyImage
                                        image={item.node.image.childImageSharp.gatsbyImageData}
                                        className="cardIcon"
                                        alt={item.node.text}
                                        loading="eager" />
								</div>
								<div className="machineSelectorCardText">
									<FormattedMessage id={item.node.text} />
								</div>
								<div className={item.node.colorClass}></div>
							</div>
						</Link>
                    );
				})}
			</div>
			<div className="machineSelectorGridDivLg">
				<svg
					className="machineSelectorGridLg"
					viewBox="0 0 1098 100"
					fill="none"
					alt="tehnomatikSvg"
				>
					<line x1="1" x2="1" y2="50" stroke="#949292" />
					<line x1="366.26" x2="366.26" y2="50" stroke="#949292" />
					<line x1="731.52" x2="731.52" y2="50" stroke="#949292" />
					<line x1="1096.78" x2="1096.78" y2="50" stroke="#949292" />
					<line x1="1098" y1="51" y2="51" stroke="#949292" />
					<line x1="550" y1="50" x2="550" y2="100" stroke="#949292" />
				</svg>
			</div>
			<div className="machineSelectorGridDivMid">
				<svg
					className="machineSelectorGridMid"
					viewBox="0 0 260 56"
					fill="none"
					alt="tehnomatikSvg"
				>
					<path d="M259 0V28H1V0" stroke="#949292" />
					<path d="M130 55.5V28" stroke="#949292" />
				</svg>
			</div>
			<div className="machineSelectorProcessLevel">
				<Link to="/machines/processLines" className="machineSelectorLinks">
					<div className="machineSelectorProcessCardWrapper">
						<div className="machineSelectorIconProcess">
							<GatsbyImage
                                image={data.processIcon.childImageSharp.gatsbyImageData}
                                className="cardIconProcess"
                                alt="cardIconProcessLines"
                                loading="eager" />
						</div>
						<div className="machineSelectorProcessText">
							<FormattedMessage id="machineSelection.processLines" />
						</div>
						<div className="machineSelectorSideColor5"></div>
					</div>
				</Link>
			</div>
		</div>
    );
};

export default MachineSelector;
